import React from "react"
import { Link } from "gatsby"

const textShadow = { textShadow: "0px 0px 2px #FFFFFF" }

export function Logo() {
  return (
    <>
      <Link
        className="underline text-3xl tracking-widest font-thin text-teal-100"
        to="/"
        style={textShadow}
      >
        Ken Global Flavours
      </Link>
      <div className="text-xs  text-teal-100" style={textShadow}>
        Flavoured Premixes
      </div>
    </>
  )
}

export default function Header() {
  return (
    <header className="pt-2 pb-4 bg-green-800 text-center">
      <Logo />
    </header>
  )
}
