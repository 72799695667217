import React from "react"
import { Logo } from "./header"

export function Footer() {
  return (
    <>
      <footer className="bg-green-800 py-10 px-3 text-teal-100">
        <div className="max-w-4xl mx-auto flex flex-col md:flex-row flex-shrink-0">
          <section className="sm:py-5 md:py-0">
            <h3 className="text-3xl">Products</h3>
            <p className="mt-2 md:mt-6">
              <a href="/#" className="underline">
                Coffee
              </a>
            </p>
            <p className="mt-2">
              <a href="/#" className="underline">
                Tea
              </a>
            </p>
            <p className="mt-2">
              <a href="/#" className="underline">
                Dairy Whiteners
              </a>
            </p>
            <p className="mt-2">
              <a href="/#" className="underline">
                Vending Machines
              </a>
            </p>
          </section>
          <section className="mt-5 md:mt-0 md:ml-auto">
            <Logo />
            <p className="mt-2 flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                fill="currentColor"
                className="text-green-500 w-5"
              >
                <path d="M48.58 0C21.793 0 0 21.793 0 48.58s21.793 48.58 48.58 48.58 48.58-21.793 48.58-48.58S75.367 0 48.58 0zm0 86.823c-21.087 0-38.244-17.155-38.244-38.243S27.493 10.337 48.58 10.337 86.824 27.492 86.824 48.58 69.667 86.823 48.58 86.823z" />
                <path d="M73.898 47.08H52.066V20.83a4 4 0 00-8 0v30.25a4 4 0 004 4h25.832a4 4 0 000-8z" />
              </svg>
              <span className="ml-2">Everyday: 9AM - 6PM IST</span>
            </p>
            <p className="mt-2 flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 308 308"
                fill="currentColor"
                className="text-green-500 w-5"
              >
                <path d="M33 67s30-35 45-34c15 2 35 35 41 54 5 19-7 32-12 39-4 6-8 11-8 11 12 36 61 85 97 97 0 0 5-3 12-8 6-5 19-17 39-11 19 5 52 26 53 40 1 15-34 45-34 45C148 305 29 185 33 67zm124-5c-13 0-13-20 0-20 36 0 70 14 95 39s39 60 39 95c0 13-19 13-19 0 0-30-12-59-34-81-21-21-50-33-81-33zm1 41c-13 0-13-19 0-19 24 0 48 9 65 27 17 17 27 40 27 65 0 12-20 13-20 0 0-20-8-38-21-51-14-14-32-22-51-22z" />
              </svg>
              <span className="ml-2">+91-9810325805</span>
            </p>
            <p className="mt-2 flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 308 308"
                fill="currentColor"
                className="text-green-500 w-5"
              >
                <path d="M33 67s30-35 45-34c15 2 35 35 41 54 5 19-7 32-12 39-4 6-8 11-8 11 12 36 61 85 97 97 0 0 5-3 12-8 6-5 19-17 39-11 19 5 52 26 53 40 1 15-34 45-34 45C148 305 29 185 33 67zm124-5c-13 0-13-20 0-20 36 0 70 14 95 39s39 60 39 95c0 13-19 13-19 0 0-30-12-59-34-81-21-21-50-33-81-33zm1 41c-13 0-13-19 0-19 24 0 48 9 65 27 17 17 27 40 27 65 0 12-20 13-20 0 0-20-8-38-21-51-14-14-32-22-51-22z" />
              </svg>
              <span className="ml-2">+91-8510008687</span>
            </p>
            <p className="mt-2 flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 511.974 511.974"
                fill="currentColor"
                className="text-green-500 w-5"
              >
                <path d="M511.872 195.725a8.065 8.065 0 00-.35-1.732 8.533 8.533 0 00-.486-1.468 7.655 7.655 0 00-.853-1.306 8.046 8.046 0 00-1.135-1.323c-.137-.119-.196-.282-.341-.401l-82.065-63.735V59.704c0-14.138-11.462-25.6-25.6-25.6h-92.476L271.539 5.355a25.302 25.302 0 00-31.121 0l-37.035 28.749h-92.476c-14.138 0-25.6 11.461-25.6 25.6v66.057L3.268 189.496c-.145.12-.205.282-.341.401a8.005 8.005 0 00-1.135 1.323 7.616 7.616 0 00-.853 1.306 8.42 8.42 0 00-.486 1.468 8.087 8.087 0 00-.35 1.707c0 .179-.102.333-.102.512V486.37a25.313 25.313 0 005.009 15.061c.051.077.06.171.119.239.06.068.188.145.273.239a25.475 25.475 0 0020.173 10.061h460.8a25.482 25.482 0 0020.241-10.103c.068-.085.171-.111.23-.196.06-.085.068-.162.12-.239a25.317 25.317 0 005.009-15.061V196.237c-.001-.179-.094-.333-.103-.512zM250.854 18.82a8.182 8.182 0 0110.18 0l19.686 15.283h-49.493l19.627-15.283zM27.725 494.904l223.13-173.321a8.193 8.193 0 0110.18 0l223.189 173.321H27.725zM494.908 481.6L271.539 308.117a25.319 25.319 0 00-31.121 0L17.041 481.6V209.233L156.877 317.82a8.536 8.536 0 0011.977-1.515 8.536 8.536 0 00-1.515-11.977L25.276 194.018l60.032-46.652v65.937a8.533 8.533 0 0017.066 0v-153.6a8.533 8.533 0 018.533-8.533H401.04a8.533 8.533 0 018.533 8.533v153.6a8.533 8.533 0 0017.066 0v-65.937l60.032 46.652-142.31 110.507a8.533 8.533 0 1010.462 13.482l140.083-108.774V481.6z" />
                <path d="M358.374 204.77v-34.133c0-56.554-45.846-102.4-102.4-102.4-56.554 0-102.4 45.846-102.4 102.4s45.846 102.4 102.4 102.4c4.713 0 8.533-3.82 8.533-8.533s-3.82-8.533-8.533-8.533c-47.128 0-85.333-38.205-85.333-85.333s38.205-85.333 85.333-85.333 85.333 38.205 85.333 85.333v34.133c0 9.426-7.641 17.067-17.067 17.067s-17.067-7.641-17.067-17.067v-34.133c0-4.713-3.82-8.533-8.533-8.533s-8.533 3.82-8.533 8.533c0 18.851-15.282 34.133-34.133 34.133-18.851 0-34.133-15.282-34.133-34.133s15.282-34.133 34.133-34.133c4.713 0 8.533-3.82 8.533-8.533s-3.82-8.533-8.533-8.533c-22.915-.051-43.074 15.13-49.354 37.168-6.28 22.038 2.847 45.565 22.347 57.601 19.5 12.036 44.622 9.65 61.507-5.843 1.858 18.046 17.543 31.464 35.659 30.505 18.117-.96 32.298-15.958 32.241-34.1z" />
              </svg>
              <span className="ml-2">kenglobalflavours@gmail.com</span>
            </p>
          </section>
        </div>
      </footer>
      <section className="bg-black text-green-100 text-xs py-1 px-5 text-center md:text-right">
        © 2020 Ken Global Flavours Pvt. Ltd. All rights reserved
      </section>
    </>
  )
}
