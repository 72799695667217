import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import "typeface-muli"
import "./layout.css"
import { Footer } from "./footer"

const Layout = ({ children }) => {
  return (
    <div className="font-body bg-gray-100 min-h-screen flex flex-col">
      <Header />
      <main className="flex-grow">{children}</main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
